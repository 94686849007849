import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Layout, SEO } from '../components/common';
import { Header } from '../components/theme';

import { Container, PageWrapper, Details, OneLiner, AlignLeft } from '../styles';

const BlogPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  box-shadow: 5px 5px 5px gray;
  padding: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FlexItem = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Posts = () => {
  const data = useStaticQuery(graphql`
    query PostImages {
      brokenComputerImage: file(relativePath: { eq: "broken-computer.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      diversityInclusionImage: file(relativePath: { eq: "diversity-inclusion.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={Container}>
        <Details>
          <h1>Posts</h1>

          <div>
            <BlogPostWrapper onClick={() => navigate('/algorithmic-bias')}>
              <FlexItem>
                <GatsbyImage
                  formats={['png']}
                  image={data.brokenComputerImage.childImageSharp.gatsbyImageData}
                  alt="A broken computer"
                  layout="constrained"
                />
              </FlexItem>
              <FlexItem>
                <h2>Algorithmic Bias</h2>
                <OneLiner>Algorithms can be as flawed as humans and the data used to train them.</OneLiner>
                <AlignLeft>Click to read more {`>>`}</AlignLeft>
              </FlexItem>
            </BlogPostWrapper>

            <BlogPostWrapper onClick={() => navigate('/diversity-and-inclusion')}>
              <FlexItem>
                <GatsbyImage
                  formats={['png']}
                  image={data.diversityInclusionImage.childImageSharp.gatsbyImageData}
                  alt="A hand with rainbow paint on it"
                  layout="constrained"
                />
              </FlexItem>
              <FlexItem>
                <h2>Diversity and Inclusion in Computer Science</h2>
                <OneLiner>Algorithms can be as flawed as humans and the data used to train them.</OneLiner>
                <AlignLeft>Click to read more {`>>`}</AlignLeft>
              </FlexItem>
            </BlogPostWrapper>
          </div>
        </Details>
      </PageWrapper>
    </Layout>
  );
};

export default Posts;
